import styled from "styled-components";

export const SCStructure = styled.section`
  width: 100%;

  img {
    max-width: 100%;
    object-fit: cover;
  }
`;
