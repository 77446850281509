

export const firebaseConfig = {
    apiKey: "AIzaSyB_wwM1aOzYEJ53jvmWs6GEht2iZcHZbzg",
    authDomain: "global-4c1c2.firebaseapp.com",
    projectId: "global-4c1c2",
    storageBucket: "global-4c1c2.appspot.com",
    messagingSenderId: "1054693683193",
    appId: "1:1054693683193:web:fdf47a1f519dce7e029728",
    measurementId: "G-6ES8YTE707"
};


