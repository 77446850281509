import styled from "styled-components";
import { Menu } from "antd";

export const DropDownMenu = styled(Menu)`
  padding: 4px 15px;
  & li {
    font-size: 12px;

    &:hover {
      background: transparent;
    }

    & a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const ButtonChat = styled.div`
  right: 100px;
  bottom: 40px;
  z-index: 999;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 60px;
    height: 60px;
    right: 50px;
    & svg {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 500px) {
    right: 20px;
  }
`;

export const MenuDropDown = styled(Menu)`
  border-radius: 12px;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
  padding: 0;
  //width: 462px;
  //padding: 20px 40px 20px 20px;
  @media (max-width: 768px) {
    padding: 15px;
  }

  .item-menu-group {
    padding-bottom: 15px;

    &:first-child {
      margin-top: 0;
      border-bottom: 1px solid #eaecf0;
    }

    & > p {
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 16px;
      color: #141414;
      opacity: 0.7;
      padding: 14px 0 0 14px;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }

    .ant-dropdown-menu-item {
      padding: 4px;
      margin: 0 10px 12px 20px;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background: #f8fbff !important;
        border-radius: 12px;
      }

      .ant-dropdown-menu-title-content {
        display: flex;
        margin-right: 10px;

        .group-svg {
          background: rgba(233, 83, 47, 0.1);
          border-radius: 8px;
          padding: 6px;
          margin-right: 12px;
          width: 38px;
          height: 38px;
          @media (max-width: 768px) {
            padding: 9px;
          }

          svg {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .text-size {
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 191px;
          }
        }

        .group-text {
          .text-new {
            display: flex;
            align-items: center;

            div {
              font-weight: 600;
              font-size: 12px;
              color: #175cd3;
              background: #eff8ff;
              border-radius: 16px;
              padding: 2px 8px;
              width: fit-content;
              margin-right: 6px;
              line-height: 16px;
              @media (max-width: 768px) {
                font-size: 10px;
              }
            }

            p {
              font-weight: 400;
              font-size: 16px;
              color: #141414;
              margin-bottom: 0px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 180px;
              @media (max-width: 768px) {
                font-size: 14px;
              }
            }
          }

          p {
            font-weight: 400;
            font-size: 16px;
            color: #141414;
            margin-bottom: 2px;
            @media (max-width: 768px) {
              font-size: 14px;
            }
          }

          span {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #141414;
            opacity: 0.7;
            font-weight: 400;
            font-size: 14px;
            width: 252px;
            @media (max-width: 768px) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  a {
    padding: 12px 14px;
    font-weight: 600;
    font-size: 16px;
    color: #141414;
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    @media (max-width: 768px) {
      font-size: 14px;
      padding: 15px 15px 0 15px;
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
`;

export const HeaderSection = styled.header`
  .navbar.position-fixed {
    top: 0;
    z-index: 9999;
    width: 100vw;
  }

  &.visible-hide {
    .nav-item.active::after {
      opacity: 0;
    }
  }

  .logo {
    height: 28px;
  }

  // icon active

  .nav-link.active .icon-home, .nav-link:focus .icon-home {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26.6666 26.6667C26.6666 27.0203 26.5261 27.3595 26.2761 27.6095C26.026 27.8596 25.6869 28.0001 25.3333 28.0001H6.66658C6.31296 28.0001 5.97382 27.8596 5.72378 27.6095C5.47373 27.3595 5.33325 27.0203 5.33325 26.6667V14.6667H1.33325L15.1026 2.14939C15.3481 1.92603 15.668 1.80225 15.9999 1.80225C16.3318 1.80225 16.6518 1.92603 16.8973 2.14939L30.6666 14.6667H26.6666V26.6667ZM9.99992 17.3334C9.99992 18.9247 10.6321 20.4508 11.7573 21.576C12.8825 22.7012 14.4086 23.3334 15.9999 23.3334C17.5912 23.3334 19.1173 22.7012 20.2426 21.576C21.3678 20.4508 21.9999 18.9247 21.9999 17.3334H19.3333C19.3333 18.2174 18.9821 19.0653 18.3569 19.6904C17.7318 20.3155 16.884 20.6667 15.9999 20.6667C15.1159 20.6667 14.268 20.3155 13.6429 19.6904C13.0178 19.0653 12.6666 18.2174 12.6666 17.3334H9.99992Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .nav-link.active .icon-info, .nav-link:focus .icon-info {
    background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.9998 2.88879C7.86645 2.88879 2.88867 7.86657 2.88867 13.9999C2.88867 20.1332 7.86645 25.111 13.9998 25.111C20.1331 25.111 25.1109 20.1332 25.1109 13.9999C25.1109 7.86657 20.1331 2.88879 13.9998 2.88879ZM13.9998 19.5555C13.3887 19.5555 12.8887 19.0555 12.8887 18.4444V13.9999C12.8887 13.3888 13.3887 12.8888 13.9998 12.8888C14.6109 12.8888 15.1109 13.3888 15.1109 13.9999V18.4444C15.1109 19.0555 14.6109 19.5555 13.9998 19.5555ZM15.1109 10.6666H12.8887V8.44435H15.1109V10.6666Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .nav-link.active .icon-service, .nav-link:focus .icon-service {
    background-image: url('data:image/svg+xml,%3Csvg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M8.66667 2.66675H23.3333C23.5403 2.66675 23.7445 2.71494 23.9296 2.80751C24.1148 2.90008 24.2758 3.03449 24.4 3.20008L28 8.00008V28.0001C28 28.3537 27.8595 28.6928 27.6095 28.9429C27.3594 29.1929 27.0203 29.3334 26.6667 29.3334H5.33333C4.97971 29.3334 4.64057 29.1929 4.39052 28.9429C4.14048 28.6928 4 28.3537 4 28.0001V8.00008L7.6 3.20008C7.7242 3.03449 7.88524 2.90008 8.07038 2.80751C8.25552 2.71494 8.45967 2.66675 8.66667 2.66675ZM24.6667 8.00008L22.6667 5.33341H9.33333L7.33333 8.00008H24.6667ZM12 13.3334H9.33333V16.0001C9.33333 17.7682 10.0357 19.4639 11.286 20.7141C12.5362 21.9644 14.2319 22.6667 16 22.6667C17.7681 22.6667 19.4638 21.9644 20.714 20.7141C21.9643 19.4639 22.6667 17.7682 22.6667 16.0001V13.3334H20V16.0001C20 17.0609 19.5786 18.0784 18.8284 18.8285C18.0783 19.5787 17.0609 20.0001 16 20.0001C14.9391 20.0001 13.9217 19.5787 13.1716 18.8285C12.4214 18.0784 12 17.0609 12 16.0001V13.3334Z" fill="white"/%3E%3C/svg%3E%0A');
  }

  .nav-link.active .icon-blog, .nav-link:focus .icon-blog {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.3334 29.3334H6.66675C5.60588 29.3334 4.58847 28.912 3.83832 28.1618C3.08818 27.4117 2.66675 26.3943 2.66675 25.3334V4.00008C2.66675 3.64646 2.80722 3.30732 3.05727 3.05727C3.30732 2.80722 3.64646 2.66675 4.00008 2.66675H22.6667C23.0204 2.66675 23.3595 2.80722 23.6096 3.05727C23.8596 3.30732 24.0001 3.64646 24.0001 4.00008V13.3334H29.3334V25.3334C29.3334 26.3943 28.912 27.4117 28.1618 28.1618C27.4117 28.912 26.3943 29.3334 25.3334 29.3334ZM24.0001 16.0001V25.3334C24.0001 25.687 24.1406 26.0262 24.3906 26.2762C24.6407 26.5263 24.9798 26.6667 25.3334 26.6667C25.687 26.6667 26.0262 26.5263 26.2762 26.2762C26.5263 26.0262 26.6667 25.687 26.6667 25.3334V16.0001H24.0001ZM6.66675 8.00008V16.0001H14.6667V8.00008H6.66675ZM6.66675 17.3334V20.0001H20.0001V17.3334H6.66675ZM6.66675 21.3334V24.0001H20.0001V21.3334H6.66675ZM9.33342 10.6667H12.0001V13.3334H9.33342V10.6667Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .nav-link.active .icon-networking, .nav-link:focus .icon-networking {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.8281 13.9734C18.5781 13.7234 18.239 13.583 17.8854 13.583C17.5319 13.583 17.1928 13.7234 16.9428 13.9734L16.0001 14.9147C15.7541 15.1694 15.4599 15.3726 15.1345 15.5123C14.8092 15.6521 14.4593 15.7257 14.1052 15.7287C13.7511 15.7318 13.4 15.6643 13.0722 15.5303C12.7445 15.3962 12.4468 15.1982 12.1964 14.9478C11.946 14.6974 11.748 14.3997 11.6139 14.0719C11.4798 13.7442 11.4124 13.3931 11.4154 13.039C11.4185 12.6849 11.4921 12.335 11.6318 12.0097C11.7716 11.6843 11.9747 11.3901 12.2294 11.1441L19.7361 3.63473C21.5133 3.2305 23.3728 3.39686 25.05 4.11017C26.7273 4.82347 28.137 6.0474 29.0786 7.60792C30.0203 9.16845 30.446 10.9861 30.2952 12.8025C30.1444 14.6189 29.4249 16.3415 28.2388 17.7254L25.4281 20.5721L18.8281 13.9721V13.9734ZM4.21478 5.9574C5.59446 4.5779 7.39907 3.70404 9.33686 3.47709C11.2746 3.25014 13.2323 3.68337 14.8934 4.70673L10.3428 9.25873C9.35717 10.2424 8.79535 11.5725 8.77736 12.9648C8.75937 14.3572 9.28663 15.7013 10.2465 16.7101C11.2063 17.7189 12.5227 18.3123 13.9142 18.3635C15.3057 18.4146 16.6621 17.9196 17.6934 16.9841L17.8854 16.8014L23.5428 22.4574L17.8854 28.1147C17.3854 28.6147 16.7072 28.8955 16.0001 28.8955C15.293 28.8955 14.6149 28.6147 14.1148 28.1147L4.21344 18.2134C2.58829 16.5881 1.67529 14.3838 1.67529 12.0854C1.67529 9.78698 2.58829 7.58269 4.21344 5.9574H4.21478Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .nav-link.active .icon-book, .nav-link:focus .icon-book {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28 24.0001H8C7.64638 24.0001 7.30724 24.1406 7.05719 24.3906C6.80714 24.6407 6.66667 24.9798 6.66667 25.3334C6.66667 25.687 6.80714 26.0262 7.05719 26.2762C7.30724 26.5263 7.64638 26.6667 8 26.6667H28V29.3334H8C6.93913 29.3334 5.92172 28.912 5.17157 28.1618C4.42143 27.4117 4 26.3943 4 25.3334V5.33341C4 4.62617 4.28095 3.94789 4.78105 3.4478C5.28115 2.9477 5.95942 2.66675 6.66667 2.66675H28V24.0001ZM21.3333 12.0001V9.33342H10.6667V12.0001H21.3333Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .nav-link.active .icon-list, .nav-link:focus .icon-list {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28 12.0001V27.9907C28.0012 28.1658 27.968 28.3395 27.9021 28.5017C27.8362 28.6639 27.739 28.8116 27.6161 28.9363C27.4931 29.061 27.3468 29.1602 27.1855 29.2284C27.0242 29.2965 26.8511 29.3322 26.676 29.3334H5.324C4.97308 29.3334 4.63652 29.1941 4.38826 28.9461C4.14 28.6981 4.00035 28.3617 4 28.0107V3.98941C4 3.27341 4.596 2.66675 5.33067 2.66675H18.6667V10.6667C18.6667 11.0204 18.8071 11.3595 19.0572 11.6096C19.3072 11.8596 19.6464 12.0001 20 12.0001H28ZM28 9.33342H21.3333V2.67075L28 9.33342ZM10.6667 9.33342V12.0001H14.6667V9.33342H10.6667ZM10.6667 14.6667V17.3334H21.3333V14.6667H10.6667ZM10.6667 20.0001V22.6667H21.3333V20.0001H10.6667Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .nav-link.active .icon-local, .nav-link:focus .icon-local {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.6001 15.9333C23.7296 14.8048 24.5505 13.4052 24.9842 11.8686C25.418 10.332 25.4501 8.70981 25.0774 7.15726L28.4041 5.73059C28.5055 5.68711 28.6162 5.66949 28.7261 5.67932C28.8361 5.68915 28.9418 5.72612 29.034 5.78692C29.1261 5.84772 29.2017 5.93044 29.2539 6.02766C29.3062 6.12488 29.3335 6.23355 29.3334 6.34393V25.3333L20.0001 29.3333L12.0001 25.3333L3.59608 28.9346C3.49463 28.9781 3.38397 28.9957 3.27404 28.9859C3.1641 28.976 3.05832 28.9391 2.9662 28.8783C2.87407 28.8175 2.79849 28.7348 2.74622 28.6375C2.69396 28.5403 2.66665 28.4316 2.66675 28.3213V9.33326L6.83875 7.54526C6.5452 9.04603 6.62529 10.596 7.07196 12.0585C7.51862 13.521 8.31814 14.8512 9.40008 15.9319L16.0001 22.5333L22.6001 15.9333ZM20.7147 14.0479L16.0001 18.7599L11.2854 14.0466C10.3532 13.1142 9.71848 11.9263 9.46138 10.6331C9.20429 9.33997 9.33642 7.99961 9.84105 6.78154C10.3457 5.56347 11.2002 4.52238 12.2965 3.78992C13.3928 3.05745 14.6816 2.6665 16.0001 2.6665C17.3185 2.6665 18.6074 3.05745 19.7037 3.78992C20.8 4.52238 21.6545 5.56347 22.1591 6.78154C22.6637 7.99961 22.7959 9.33997 22.5388 10.6331C22.2817 11.9263 21.6469 13.1142 20.7147 14.0466V14.0479Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .nav-link.active {
    span {
      font-weight: 600;
    }
  }

  .icon-svg {
    width: 24px;
    height: 24px;
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    margin-bottom: 4px;
  }

  // icon hover
  .nav-link:hover .icon-home {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26.6666 26.6667C26.6666 27.0203 26.5261 27.3595 26.2761 27.6095C26.026 27.8596 25.6869 28.0001 25.3333 28.0001H6.66658C6.31296 28.0001 5.97382 27.8596 5.72378 27.6095C5.47373 27.3595 5.33325 27.0203 5.33325 26.6667V14.6667H1.33325L15.1026 2.14939C15.3481 1.92603 15.668 1.80225 15.9999 1.80225C16.3318 1.80225 16.6518 1.92603 16.8973 2.14939L30.6666 14.6667H26.6666V26.6667ZM9.99992 17.3334C9.99992 18.9247 10.6321 20.4508 11.7573 21.576C12.8825 22.7012 14.4086 23.3334 15.9999 23.3334C17.5912 23.3334 19.1173 22.7012 20.2426 21.576C21.3678 20.4508 21.9999 18.9247 21.9999 17.3334H19.3333C19.3333 18.2174 18.9821 19.0653 18.3569 19.6904C17.7318 20.3155 16.884 20.6667 15.9999 20.6667C15.1159 20.6667 14.268 20.3155 13.6429 19.6904C13.0178 19.0653 12.6666 18.2174 12.6666 17.3334H9.99992Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .nav-link:hover .icon-info {
    background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.9998 2.88879C7.86645 2.88879 2.88867 7.86657 2.88867 13.9999C2.88867 20.1332 7.86645 25.111 13.9998 25.111C20.1331 25.111 25.1109 20.1332 25.1109 13.9999C25.1109 7.86657 20.1331 2.88879 13.9998 2.88879ZM13.9998 19.5555C13.3887 19.5555 12.8887 19.0555 12.8887 18.4444V13.9999C12.8887 13.3888 13.3887 12.8888 13.9998 12.8888C14.6109 12.8888 15.1109 13.3888 15.1109 13.9999V18.4444C15.1109 19.0555 14.6109 19.5555 13.9998 19.5555ZM15.1109 10.6666H12.8887V8.44435H15.1109V10.6666Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .nav-link:hover .icon-service {
    background-image: url('data:image/svg+xml,%3Csvg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M8.66667 2.66675H23.3333C23.5403 2.66675 23.7445 2.71494 23.9296 2.80751C24.1148 2.90008 24.2758 3.03449 24.4 3.20008L28 8.00008V28.0001C28 28.3537 27.8595 28.6928 27.6095 28.9429C27.3594 29.1929 27.0203 29.3334 26.6667 29.3334H5.33333C4.97971 29.3334 4.64057 29.1929 4.39052 28.9429C4.14048 28.6928 4 28.3537 4 28.0001V8.00008L7.6 3.20008C7.7242 3.03449 7.88524 2.90008 8.07038 2.80751C8.25552 2.71494 8.45967 2.66675 8.66667 2.66675ZM24.6667 8.00008L22.6667 5.33341H9.33333L7.33333 8.00008H24.6667ZM12 13.3334H9.33333V16.0001C9.33333 17.7682 10.0357 19.4639 11.286 20.7141C12.5362 21.9644 14.2319 22.6667 16 22.6667C17.7681 22.6667 19.4638 21.9644 20.714 20.7141C21.9643 19.4639 22.6667 17.7682 22.6667 16.0001V13.3334H20V16.0001C20 17.0609 19.5786 18.0784 18.8284 18.8285C18.0783 19.5787 17.0609 20.0001 16 20.0001C14.9391 20.0001 13.9217 19.5787 13.1716 18.8285C12.4214 18.0784 12 17.0609 12 16.0001V13.3334Z" fill="white"/%3E%3C/svg%3E%0A');
  }

  .nav-link:hover .icon-blog {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.3334 29.3334H6.66675C5.60588 29.3334 4.58847 28.912 3.83832 28.1618C3.08818 27.4117 2.66675 26.3943 2.66675 25.3334V4.00008C2.66675 3.64646 2.80722 3.30732 3.05727 3.05727C3.30732 2.80722 3.64646 2.66675 4.00008 2.66675H22.6667C23.0204 2.66675 23.3595 2.80722 23.6096 3.05727C23.8596 3.30732 24.0001 3.64646 24.0001 4.00008V13.3334H29.3334V25.3334C29.3334 26.3943 28.912 27.4117 28.1618 28.1618C27.4117 28.912 26.3943 29.3334 25.3334 29.3334ZM24.0001 16.0001V25.3334C24.0001 25.687 24.1406 26.0262 24.3906 26.2762C24.6407 26.5263 24.9798 26.6667 25.3334 26.6667C25.687 26.6667 26.0262 26.5263 26.2762 26.2762C26.5263 26.0262 26.6667 25.687 26.6667 25.3334V16.0001H24.0001ZM6.66675 8.00008V16.0001H14.6667V8.00008H6.66675ZM6.66675 17.3334V20.0001H20.0001V17.3334H6.66675ZM6.66675 21.3334V24.0001H20.0001V21.3334H6.66675ZM9.33342 10.6667H12.0001V13.3334H9.33342V10.6667Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .nav-link:hover .icon-networking {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.8281 13.9734C18.5781 13.7234 18.239 13.583 17.8854 13.583C17.5319 13.583 17.1928 13.7234 16.9428 13.9734L16.0001 14.9147C15.7541 15.1694 15.4599 15.3726 15.1345 15.5123C14.8092 15.6521 14.4593 15.7257 14.1052 15.7287C13.7511 15.7318 13.4 15.6643 13.0722 15.5303C12.7445 15.3962 12.4468 15.1982 12.1964 14.9478C11.946 14.6974 11.748 14.3997 11.6139 14.0719C11.4798 13.7442 11.4124 13.3931 11.4154 13.039C11.4185 12.6849 11.4921 12.335 11.6318 12.0097C11.7716 11.6843 11.9747 11.3901 12.2294 11.1441L19.7361 3.63473C21.5133 3.2305 23.3728 3.39686 25.05 4.11017C26.7273 4.82347 28.137 6.0474 29.0786 7.60792C30.0203 9.16845 30.446 10.9861 30.2952 12.8025C30.1444 14.6189 29.4249 16.3415 28.2388 17.7254L25.4281 20.5721L18.8281 13.9721V13.9734ZM4.21478 5.9574C5.59446 4.5779 7.39907 3.70404 9.33686 3.47709C11.2746 3.25014 13.2323 3.68337 14.8934 4.70673L10.3428 9.25873C9.35717 10.2424 8.79535 11.5725 8.77736 12.9648C8.75937 14.3572 9.28663 15.7013 10.2465 16.7101C11.2063 17.7189 12.5227 18.3123 13.9142 18.3635C15.3057 18.4146 16.6621 17.9196 17.6934 16.9841L17.8854 16.8014L23.5428 22.4574L17.8854 28.1147C17.3854 28.6147 16.7072 28.8955 16.0001 28.8955C15.293 28.8955 14.6149 28.6147 14.1148 28.1147L4.21344 18.2134C2.58829 16.5881 1.67529 14.3838 1.67529 12.0854C1.67529 9.78698 2.58829 7.58269 4.21344 5.9574H4.21478Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .nav-link:hover .icon-book {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28 24.0001H8C7.64638 24.0001 7.30724 24.1406 7.05719 24.3906C6.80714 24.6407 6.66667 24.9798 6.66667 25.3334C6.66667 25.687 6.80714 26.0262 7.05719 26.2762C7.30724 26.5263 7.64638 26.6667 8 26.6667H28V29.3334H8C6.93913 29.3334 5.92172 28.912 5.17157 28.1618C4.42143 27.4117 4 26.3943 4 25.3334V5.33341C4 4.62617 4.28095 3.94789 4.78105 3.4478C5.28115 2.9477 5.95942 2.66675 6.66667 2.66675H28V24.0001ZM21.3333 12.0001V9.33342H10.6667V12.0001H21.3333Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .nav-link:hover .icon-list {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28 12.0001V27.9907C28.0012 28.1658 27.968 28.3395 27.9021 28.5017C27.8362 28.6639 27.739 28.8116 27.6161 28.9363C27.4931 29.061 27.3468 29.1602 27.1855 29.2284C27.0242 29.2965 26.8511 29.3322 26.676 29.3334H5.324C4.97308 29.3334 4.63652 29.1941 4.38826 28.9461C4.14 28.6981 4.00035 28.3617 4 28.0107V3.98941C4 3.27341 4.596 2.66675 5.33067 2.66675H18.6667V10.6667C18.6667 11.0204 18.8071 11.3595 19.0572 11.6096C19.3072 11.8596 19.6464 12.0001 20 12.0001H28ZM28 9.33342H21.3333V2.67075L28 9.33342ZM10.6667 9.33342V12.0001H14.6667V9.33342H10.6667ZM10.6667 14.6667V17.3334H21.3333V14.6667H10.6667ZM10.6667 20.0001V22.6667H21.3333V20.0001H10.6667Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .nav-link:hover .icon-local {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.6001 15.9333C23.7296 14.8048 24.5505 13.4052 24.9842 11.8686C25.418 10.332 25.4501 8.70981 25.0774 7.15726L28.4041 5.73059C28.5055 5.68711 28.6162 5.66949 28.7261 5.67932C28.8361 5.68915 28.9418 5.72612 29.034 5.78692C29.1261 5.84772 29.2017 5.93044 29.2539 6.02766C29.3062 6.12488 29.3335 6.23355 29.3334 6.34393V25.3333L20.0001 29.3333L12.0001 25.3333L3.59608 28.9346C3.49463 28.9781 3.38397 28.9957 3.27404 28.9859C3.1641 28.976 3.05832 28.9391 2.9662 28.8783C2.87407 28.8175 2.79849 28.7348 2.74622 28.6375C2.69396 28.5403 2.66665 28.4316 2.66675 28.3213V9.33326L6.83875 7.54526C6.5452 9.04603 6.62529 10.596 7.07196 12.0585C7.51862 13.521 8.31814 14.8512 9.40008 15.9319L16.0001 22.5333L22.6001 15.9333ZM20.7147 14.0479L16.0001 18.7599L11.2854 14.0466C10.3532 13.1142 9.71848 11.9263 9.46138 10.6331C9.20429 9.33997 9.33642 7.99961 9.84105 6.78154C10.3457 5.56347 11.2002 4.52238 12.2965 3.78992C13.3928 3.05745 14.6816 2.6665 16.0001 2.6665C17.3185 2.6665 18.6074 3.05745 19.7037 3.78992C20.8 4.52238 21.6545 5.56347 22.1591 6.78154C22.6637 7.99961 22.7959 9.33997 22.5388 10.6331C22.2817 11.9263 21.6469 13.1142 20.7147 14.0466V14.0479Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  // icon inactive

  .icon-home {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99992 25.3334H23.9999V12.2094L15.9999 4.93739L7.99992 12.2094V25.3334ZM25.3333 28.0001H6.66658C6.31296 28.0001 5.97382 27.8596 5.72378 27.6095C5.47373 27.3595 5.33325 27.0203 5.33325 26.6667V14.6667H1.33325L15.1026 2.14939C15.3481 1.92603 15.668 1.80225 15.9999 1.80225C16.3318 1.80225 16.6518 1.92603 16.8973 2.14939L30.6666 14.6667H26.6666V26.6667C26.6666 27.0203 26.5261 27.3595 26.2761 27.6095C26.026 27.8596 25.6869 28.0001 25.3333 28.0001ZM9.99992 17.3334H12.6666C12.6666 18.2174 13.0178 19.0653 13.6429 19.6904C14.268 20.3155 15.1159 20.6667 15.9999 20.6667C16.884 20.6667 17.7318 20.3155 18.3569 19.6904C18.9821 19.0653 19.3333 18.2174 19.3333 17.3334H21.9999C21.9999 18.9247 21.3678 20.4508 20.2426 21.576C19.1173 22.7012 17.5912 23.3334 15.9999 23.3334C14.4086 23.3334 12.8825 22.7012 11.7573 21.576C10.6321 20.4508 9.99992 18.9247 9.99992 17.3334Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .icon-info {
    background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.9998 2.88879C7.86645 2.88879 2.88867 7.86657 2.88867 13.9999C2.88867 20.1332 7.86645 25.111 13.9998 25.111C20.1331 25.111 25.1109 20.1332 25.1109 13.9999C25.1109 7.86657 20.1331 2.88879 13.9998 2.88879ZM13.9998 19.5555C13.3887 19.5555 12.8887 19.0555 12.8887 18.4444V13.9999C12.8887 13.3888 13.3887 12.8888 13.9998 12.8888C14.6109 12.8888 15.1109 13.3888 15.1109 13.9999V18.4444C15.1109 19.0555 14.6109 19.5555 13.9998 19.5555ZM15.1109 10.6666H12.8887V8.44435H15.1109V10.6666Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .icon-service {
    background-image: url('data:image/svg+xml,%3Csvg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M8.66667 2.66675H23.3333C23.5403 2.66675 23.7445 2.71494 23.9296 2.80751C24.1148 2.90008 24.2758 3.03449 24.4 3.20008L28 8.00008V28.0001C28 28.3537 27.8595 28.6928 27.6095 28.9429C27.3594 29.1929 27.0203 29.3334 26.6667 29.3334H5.33333C4.97971 29.3334 4.64057 29.1929 4.39052 28.9429C4.14048 28.6928 4 28.3537 4 28.0001V8.00008L7.6 3.20008C7.7242 3.03449 7.88524 2.90008 8.07038 2.80751C8.25552 2.71494 8.45967 2.66675 8.66667 2.66675ZM25.3333 10.6667H6.66667V26.6667H25.3333V10.6667ZM24.6667 8.00008L22.6667 5.33341H9.33333L7.33333 8.00008H24.6667ZM12 13.3334V16.0001C12 17.0609 12.4214 18.0784 13.1716 18.8285C13.9217 19.5787 14.9391 20.0001 16 20.0001C17.0609 20.0001 18.0783 19.5787 18.8284 18.8285C19.5786 18.0784 20 17.0609 20 16.0001V13.3334H22.6667V16.0001C22.6667 17.7682 21.9643 19.4639 20.714 20.7141C19.4638 21.9644 17.7681 22.6667 16 22.6667C14.2319 22.6667 12.5362 21.9644 11.286 20.7141C10.0357 19.4639 9.33333 17.7682 9.33333 16.0001V13.3334H12Z" fill="white" fill-opacity="0.7"/%3E%3C/svg%3E%0A');
  }

  .icon-blog {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.3334 26.6667V5.33341H5.33341V25.3334C5.33341 25.687 5.47389 26.0262 5.72394 26.2762C5.97399 26.5263 6.31313 26.6667 6.66675 26.6667H21.3334ZM25.3334 29.3334H6.66675C5.60588 29.3334 4.58847 28.912 3.83832 28.1618C3.08818 27.4117 2.66675 26.3943 2.66675 25.3334V4.00008C2.66675 3.64646 2.80722 3.30732 3.05727 3.05727C3.30732 2.80722 3.64646 2.66675 4.00008 2.66675H22.6667C23.0204 2.66675 23.3595 2.80722 23.6096 3.05727C23.8596 3.30732 24.0001 3.64646 24.0001 4.00008V13.3334H29.3334V25.3334C29.3334 26.3943 28.912 27.4117 28.1618 28.1618C27.4117 28.912 26.3943 29.3334 25.3334 29.3334ZM24.0001 16.0001V25.3334C24.0001 25.687 24.1406 26.0262 24.3906 26.2762C24.6407 26.5263 24.9798 26.6667 25.3334 26.6667C25.687 26.6667 26.0262 26.5263 26.2762 26.2762C26.5263 26.0262 26.6667 25.687 26.6667 25.3334V16.0001H24.0001ZM8.00008 8.00008H16.0001V16.0001H8.00008V8.00008ZM10.6667 10.6667V13.3334H13.3334V10.6667H10.6667ZM8.00008 17.3334H18.6667V20.0001H8.00008V17.3334ZM8.00008 21.3334H18.6667V24.0001H8.00008V21.3334Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .icon-networking {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.21472 5.95864C5.76148 4.41161 7.83606 3.50676 10.0222 3.42564C12.2083 3.34453 14.3442 4.09316 16.0014 5.52131C17.6571 4.09554 19.7902 3.34775 21.9737 3.42766C24.1572 3.50757 26.2299 4.40927 27.777 5.95225C29.324 7.49523 30.2311 9.56561 30.3167 11.7489C30.4024 13.9322 29.6602 16.0672 28.2387 17.7266L17.8854 28.1133C17.4097 28.5891 16.7718 28.8676 16.0994 28.8928C15.4271 28.918 14.77 28.6882 14.2601 28.2493L14.1134 28.1146L3.76139 17.7266C2.34071 16.0687 1.59816 13.9356 1.68221 11.7538C1.76626 9.57205 2.6707 7.5024 4.21472 5.95864ZM6.10006 7.84398C5.00971 8.93462 4.38119 10.4032 4.34495 11.945C4.3087 13.4868 4.86751 14.9833 5.90539 16.124L6.10006 16.3293L16.0001 26.2293L23.0707 19.1573L18.3574 14.444L16.9441 15.8573C16.5727 16.2289 16.1318 16.5236 15.6465 16.7248C15.1612 16.9259 14.641 17.0295 14.1157 17.0296C13.0547 17.0299 12.0371 16.6087 11.2867 15.8586C10.5363 15.1086 10.1146 14.0912 10.1144 13.0303C10.1141 11.9693 10.5354 10.9517 11.2854 10.2013L14.0881 7.39731C12.9727 6.50714 11.5766 6.04356 10.1503 6.08974C8.72401 6.13593 7.36084 6.68885 6.30539 7.64931L6.10006 7.84398ZM17.4147 11.6146C17.6648 11.3647 18.0038 11.2243 18.3574 11.2243C18.7109 11.2243 19.05 11.3647 19.3001 11.6146L24.9561 17.2706L25.9001 16.3293C27.0087 15.2215 27.6398 13.7239 27.6585 12.1567C27.6772 10.5896 27.082 9.07733 26.0001 7.94338C24.9182 6.80943 23.4356 6.1438 21.8693 6.08885C20.303 6.03389 18.7774 6.59397 17.6187 7.64931L17.4147 7.84398L13.1721 12.0866C12.941 12.3176 12.8027 12.6254 12.7835 12.9515C12.7643 13.2777 12.8656 13.5995 13.0681 13.856L13.1721 13.972C13.403 14.2031 13.7108 14.3413 14.0369 14.3605C14.3631 14.3797 14.685 14.2784 14.9414 14.076L15.0574 13.972L17.4147 11.6146Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .icon-book {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28 24.0001H8C7.64638 24.0001 7.30724 24.1406 7.05719 24.3906C6.80714 24.6407 6.66667 24.9798 6.66667 25.3334C6.66667 25.687 6.80714 26.0262 7.05719 26.2762C7.30724 26.5263 7.64638 26.6667 8 26.6667H28V29.3334H8C6.93913 29.3334 5.92172 28.912 5.17157 28.1618C4.42143 27.4117 4 26.3943 4 25.3334V5.33341C4 4.62617 4.28095 3.94789 4.78105 3.4478C5.28115 2.9477 5.95942 2.66675 6.66667 2.66675H28V24.0001ZM6.66667 21.4001C6.88267 21.3561 7.10533 21.3334 7.33333 21.3334H25.3333V5.33341H6.66667V21.4001ZM21.3333 12.0001H10.6667V9.33342H21.3333V12.0001Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .icon-list {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28 10.6667V27.9907C28.0012 28.1658 27.968 28.3395 27.9021 28.5017C27.8362 28.6639 27.739 28.8116 27.6161 28.9363C27.4931 29.061 27.3468 29.1602 27.1855 29.2284C27.0242 29.2965 26.8511 29.3322 26.676 29.3334H5.324C4.97308 29.3334 4.63652 29.1941 4.38826 28.9461C4.14 28.6981 4.00035 28.3617 4 28.0107V3.98941C4 3.27341 4.59867 2.66675 5.336 2.66675H19.996L28 10.6667ZM25.3333 12.0001H18.6667V5.33341H6.66667V26.6667H25.3333V12.0001ZM10.6667 9.33342H14.6667V12.0001H10.6667V9.33342ZM10.6667 14.6667H21.3333V17.3334H10.6667V14.6667ZM10.6667 20.0001H21.3333V22.6667H10.6667V20.0001Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .icon-local {
    background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.33341 8.1906V25.2893L12.0867 22.3959L20.0867 26.3959L26.6667 23.5733V6.47594L28.4041 5.73194C28.5055 5.68845 28.6162 5.67083 28.7261 5.68066C28.8361 5.69049 28.9418 5.72746 29.034 5.78826C29.1261 5.84906 29.2017 5.93178 29.2539 6.029C29.3062 6.12622 29.3335 6.23489 29.3334 6.34527V25.3333L20.0001 29.3333L12.0001 25.3333L3.59608 28.9346C3.49463 28.9781 3.38397 28.9957 3.27404 28.9859C3.1641 28.976 3.05832 28.9391 2.9662 28.8783C2.87407 28.8175 2.79849 28.7348 2.74622 28.6375C2.69396 28.5403 2.66665 28.4316 2.66675 28.3213V9.33327L5.33341 8.1906ZM21.6574 14.9906L16.0001 20.6466L10.3427 14.9906C9.22406 13.8717 8.46227 12.4463 8.1537 10.8945C7.84513 9.34264 8.00363 7.73418 8.60917 6.27245C9.21472 4.81072 10.2401 3.56137 11.5557 2.68238C12.8712 1.80339 14.4179 1.33423 16.0001 1.33423C17.5823 1.33423 19.1289 1.80339 20.4445 2.68238C21.7601 3.56137 22.7854 4.81072 23.391 6.27245C23.9965 7.73418 24.155 9.34264 23.8465 10.8945C23.5379 12.4463 22.7761 13.8717 21.6574 14.9906ZM16.0001 16.8759L19.7707 13.1039C20.5168 12.3581 21.0249 11.4078 21.2308 10.3732C21.4367 9.3386 21.3312 8.26616 20.9276 7.29152C20.524 6.31689 19.8404 5.48384 18.9633 4.89774C18.0862 4.31163 17.055 3.9988 16.0001 3.9988C14.9452 3.9988 13.914 4.31163 13.0369 4.89774C12.1598 5.48384 11.4762 6.31689 11.0726 7.29152C10.6689 8.26616 10.5634 9.3386 10.7693 10.3732C10.9753 11.4078 11.4834 12.3581 12.2294 13.1039L16.0001 16.8759Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  .navbar__logo {
    flex: 0 0 auto;
    width: 95px;
  }

  .navbar {
    background-color: #031c3a;
    padding: 10px;
    height: 51px;
    z-index: 11;
    position: relative;
    @media (max-width: 991px) {
      height: auto;
    }

    .nav-item {
      transition: all 0.2s linear;
      flex: 0 0 auto;
      width: 119px;
      text-align: center;
      @media (max-width: 991px) {
        width: auto;
        text-align: left;
      }
    }

    .ant-dropdown-trigger {
      font-size: 0.9rem;
      text-decoration: none;
      padding: 10px 0px;
      color: rgba(255, 255, 255, 0.55);
      @media (max-width: 992px) {
        align-self: flex-start;
        padding: 0px 0px 0 22px;
      }
    }

    .navbar-nav {
      .nav-link {
        align-self: flex-start;
      }
    }

    .dropdown-block {
      .ant-dropdown-menu {
        padding: 4px 20px;

        & a {
          text-decoration: none !important;
        }
      }
    }

    &.global-pay {
      background-color: rgb(48, 48, 48);

      .nav-item.active {
        a {
          color: #fff;
          font-weight: 700 !important;
        }

        &::after {
          border-bottom: 7px solid rgb(233, 83, 47);
        }
      }
    }

    &.global-id {
      background-color: rgb(48, 48, 48);

      .nav-item.active {
        a {
          color: #fff;
          font-weight: 700 !important;
        }

        &::after {
          border-bottom: 7px solid #8f73fe;
        }
      }
    }

    &.telegram-pay {
      background-color: #141414;

      .nav-item.active {
        &::after {
          border-bottom: 7px solid #6ea8ff;
        }
      }
    }

    @media (max-width: 991px) {
      .navbar-nav {
        padding-top: 20px;
      }
    }
  }

  .nav-item.active {
    position: relative;

    a {
      font-weight: 700;
      color: #fff;
    }

    @media (max-width: 991px) {
      background-color: #0d3664;
      a {
        color: #fff;
      }

      &::after {
        display: none;
      }
    }

    &::after {
      content: "";
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      bottom: 0px;
      width: 7px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #0a3f7b;
    }
  }

  .nav-link {
    font-size: 14px;
    margin-left: 7px;
    margin-right: 7px;
    padding: 15px;

    & > div {
      width: 22px;
      height: 22px;
      margin: 0 auto;

      & svg {
        width: 100%;
        height: 100%;
        fill: rgb(255, 255, 255);
      }
    }
  }

  .navbar-submenu {
    padding-top: 5px;

    &.global {
      background-color: #0a3f7b;
      position: relative;
      overflow: hidden;

      .bg-video {
        position: absolute;
        width: 93%;
        top: -212%;
        right: -32%;
        opacity: 0.2;
      }

      .submenu-wrap {
        @media (max-width: 768px) {
          width: 600px;
          justify-content: flex-start;
        }
      }
    }

    &.global-pay {
      background-color: rgb(233, 83, 47);
    }

    &.global-id {
      background-color: #8f73fe;

      .submenu-wrap {
        justify-content: center !important;
        width: auto;
      }
    }

    &.telegram-pay {
      background-color: #6ea8ff;
    }

    .container {
      overflow-x: auto;
    }

    .submenu-wrap {
      text-align: center;
      justify-content: center;
      display: flex;
      z-index: 11;
      position: relative;
    }

    .submenu-wrap.global-pay-wrap {
      @media (max-width: 768px) {
        width: 600px;
        justify-content: flex-start !important;
      }
    }

    .global-pay {
      .nav-link {
        display: flex !important;
        flex-direction: column !important;
        align-items: center !important;
        padding: 10px !important;
        margin-left: 7px;
        margin-right: 7px;

        span {
          svg {
            margin-left: 6px;
          }
        }

        //hover
        &:hover {
          .icon-gate {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.0831 10.5001L21.2851 11.2211C21.3593 11.2655 21.4206 11.3283 21.4633 11.4035C21.5059 11.4787 21.5283 11.5636 21.5283 11.6501C21.5283 11.7365 21.5059 11.8214 21.4633 11.8966C21.4206 11.9718 21.3593 12.0347 21.2851 12.0791L12.0001 17.6501L2.7151 12.0791C2.64095 12.0347 2.57956 11.9718 2.53695 11.8966C2.49433 11.8214 2.47192 11.7365 2.47192 11.6501C2.47192 11.5636 2.49433 11.4787 2.53695 11.4035C2.57956 11.3283 2.64095 11.2655 2.7151 11.2211L3.9171 10.5001L12.0001 15.3501L20.0831 10.5001ZM20.0831 15.2001L21.2851 15.9211C21.3593 15.9655 21.4206 16.0283 21.4633 16.1035C21.5059 16.1787 21.5283 16.2636 21.5283 16.3501C21.5283 16.4365 21.5059 16.5214 21.4633 16.5966C21.4206 16.6718 21.3593 16.7347 21.2851 16.7791L12.5151 22.0411C12.3596 22.1345 12.1815 22.1839 12.0001 22.1839C11.8187 22.1839 11.6406 22.1345 11.4851 22.0411L2.7151 16.7791C2.64095 16.7347 2.57956 16.6718 2.53695 16.5966C2.49433 16.5214 2.47192 16.4365 2.47192 16.3501C2.47192 16.2636 2.49433 16.1787 2.53695 16.1035C2.57956 16.0283 2.64095 15.9655 2.7151 15.9211L3.9171 15.2001L12.0001 20.0501L20.0831 15.2001V15.2001ZM12.5141 1.30907L21.2851 6.57107C21.3593 6.61546 21.4206 6.67832 21.4633 6.75351C21.5059 6.82869 21.5283 6.91364 21.5283 7.00007C21.5283 7.0865 21.5059 7.17145 21.4633 7.24663C21.4206 7.32182 21.3593 7.38468 21.2851 7.42907L12.0001 13.0001L2.7151 7.42907C2.64095 7.38468 2.57956 7.32182 2.53695 7.24663C2.49433 7.17145 2.47192 7.0865 2.47192 7.00007C2.47192 6.91364 2.49433 6.82869 2.53695 6.75351C2.57956 6.67832 2.64095 6.61546 2.7151 6.57107L11.4851 1.30907C11.6406 1.21563 11.8187 1.16626 12.0001 1.16626C12.1815 1.16626 12.3596 1.21563 12.5151 1.30907H12.5141Z' fill='white'/%3E%3C/svg%3E%0A");
          }

          .icon-service {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 2H17.5C17.6552 2 17.8084 2.03614 17.9472 2.10557C18.0861 2.175 18.2069 2.2758 18.3 2.4L21 6V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V6L5.7 2.4C5.79315 2.2758 5.91393 2.175 6.05279 2.10557C6.19164 2.03614 6.34475 2 6.5 2ZM18.5 6L17 4H7L5.5 6H18.5ZM9 10H7V12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12V10H15V12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12V10Z' fill='white'/%3E%3C/svg%3E%0A");
          }

          .icon-cert {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 9V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3 2.455 3.447 2 3.998 2H14V8C14 8.26522 14.1054 8.51957 14.2929 8.70711C14.4804 8.89464 14.7348 9 15 9H21ZM21 7H16V2.003L21 7ZM8 7V9H11V7H8ZM8 11V13H16V11H8ZM8 15V17H16V15H8Z' fill='white'/%3E%3C/svg%3E%0A");
          }

          .icon-dev {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 12L18.343 17.657L16.929 16.243L21.172 12L16.929 7.757L18.343 6.343L24 12ZM2.828 12L7.071 16.243L5.657 17.657L0 12L5.657 6.343L7.07 7.757L2.828 12ZM9.788 21H7.66L14.212 3H16.34L9.788 21Z' fill='white'/%3E%3C/svg%3E%0A");
          }

          .icon-kon {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.95 11.9499C17.7971 11.1036 18.4128 10.0539 18.7381 8.90146C19.0634 7.74902 19.0875 6.53235 18.808 5.36795L21.303 4.29795C21.3791 4.26533 21.4621 4.25211 21.5445 4.25949C21.627 4.26686 21.7063 4.29459 21.7754 4.34019C21.8445 4.38579 21.9012 4.44783 21.9404 4.52074C21.9796 4.59366 22.0001 4.67516 22 4.75795V18.9999L15 21.9999L9 18.9999L2.697 21.7009C2.62091 21.7336 2.53792 21.7468 2.45547 21.7394C2.37301 21.732 2.29368 21.7043 2.22459 21.6587C2.1555 21.6131 2.09881 21.5511 2.05961 21.4781C2.02041 21.4052 1.99993 21.3237 2 21.2409V6.99995L5.129 5.65895C4.90884 6.78452 4.96891 7.94698 5.30391 9.04387C5.6389 10.1408 6.23855 11.1384 7.05 11.9489L12 16.8999L16.95 11.9499ZM15.536 10.5359L12 14.0699L8.464 10.5349C7.76487 9.83563 7.2888 8.94471 7.09598 7.97484C6.90316 7.00498 7.00225 5.99971 7.38073 5.08616C7.75921 4.1726 8.40007 3.39179 9.22229 2.84244C10.0445 2.29309 11.0111 1.99988 12 1.99988C12.9889 1.99988 13.9555 2.29309 14.7777 2.84244C15.5999 3.39179 16.2408 4.1726 16.6193 5.08616C16.9977 5.99971 17.0968 7.00498 16.904 7.97484C16.7112 8.94471 16.2351 9.83563 15.536 10.5349V10.5359Z' fill='white'/%3E%3C/svg%3E%0A");
          }
        }
      }

      .ant-dropdown-open {
        opacity: 1 !important;

        span {
          opacity: 1 !important;

          svg {
            transform: rotate(180deg);
            transition: all 0.3s;
          }
        }

        .icon-service {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='28' viewBox='0 0 24 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.66667 0.666016H19.3333C19.5403 0.666016 19.7445 0.714209 19.9296 0.806779C20.1148 0.89935 20.2758 1.03375 20.4 1.19935L24 5.99935V25.9993C24 26.353 23.8595 26.6921 23.6095 26.9422C23.3594 27.1922 23.0203 27.3327 22.6667 27.3327H1.33333C0.979711 27.3327 0.640573 27.1922 0.390524 26.9422C0.140476 26.6921 0 26.353 0 25.9993V5.99935L3.6 1.19935C3.7242 1.03375 3.88524 0.89935 4.07038 0.806779C4.25552 0.714209 4.45967 0.666016 4.66667 0.666016ZM20.6667 5.99935L18.6667 3.33268H5.33333L3.33333 5.99935H20.6667ZM8 11.3327H5.33333V13.9993C5.33333 15.7675 6.03571 17.4632 7.28596 18.7134C8.5362 19.9636 10.2319 20.666 12 20.666C13.7681 20.666 15.4638 19.9636 16.714 18.7134C17.9643 17.4632 18.6667 15.7675 18.6667 13.9993V11.3327H16V13.9993C16 15.0602 15.5786 16.0776 14.8284 16.8278C14.0783 17.5779 13.0609 17.9993 12 17.9993C10.9391 17.9993 9.92172 17.5779 9.17157 16.8278C8.42143 16.0776 8 15.0602 8 13.9993V11.3327Z' fill='white'/%3E%3C/svg%3E%0A");
        }
      }

      .nav-link.active {
        border-bottom: 2px solid #ffffff !important;

        span {
          opacity: 1;
          font-weight: 700;
        }

        //  active
        .icon-gate {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.0831 10.5001L21.2851 11.2211C21.3593 11.2655 21.4206 11.3283 21.4633 11.4035C21.5059 11.4787 21.5283 11.5636 21.5283 11.6501C21.5283 11.7365 21.5059 11.8214 21.4633 11.8966C21.4206 11.9718 21.3593 12.0347 21.2851 12.0791L12.0001 17.6501L2.7151 12.0791C2.64095 12.0347 2.57956 11.9718 2.53695 11.8966C2.49433 11.8214 2.47192 11.7365 2.47192 11.6501C2.47192 11.5636 2.49433 11.4787 2.53695 11.4035C2.57956 11.3283 2.64095 11.2655 2.7151 11.2211L3.9171 10.5001L12.0001 15.3501L20.0831 10.5001ZM20.0831 15.2001L21.2851 15.9211C21.3593 15.9655 21.4206 16.0283 21.4633 16.1035C21.5059 16.1787 21.5283 16.2636 21.5283 16.3501C21.5283 16.4365 21.5059 16.5214 21.4633 16.5966C21.4206 16.6718 21.3593 16.7347 21.2851 16.7791L12.5151 22.0411C12.3596 22.1345 12.1815 22.1839 12.0001 22.1839C11.8187 22.1839 11.6406 22.1345 11.4851 22.0411L2.7151 16.7791C2.64095 16.7347 2.57956 16.6718 2.53695 16.5966C2.49433 16.5214 2.47192 16.4365 2.47192 16.3501C2.47192 16.2636 2.49433 16.1787 2.53695 16.1035C2.57956 16.0283 2.64095 15.9655 2.7151 15.9211L3.9171 15.2001L12.0001 20.0501L20.0831 15.2001V15.2001ZM12.5141 1.30907L21.2851 6.57107C21.3593 6.61546 21.4206 6.67832 21.4633 6.75351C21.5059 6.82869 21.5283 6.91364 21.5283 7.00007C21.5283 7.0865 21.5059 7.17145 21.4633 7.24663C21.4206 7.32182 21.3593 7.38468 21.2851 7.42907L12.0001 13.0001L2.7151 7.42907C2.64095 7.38468 2.57956 7.32182 2.53695 7.24663C2.49433 7.17145 2.47192 7.0865 2.47192 7.00007C2.47192 6.91364 2.49433 6.82869 2.53695 6.75351C2.57956 6.67832 2.64095 6.61546 2.7151 6.57107L11.4851 1.30907C11.6406 1.21563 11.8187 1.16626 12.0001 1.16626C12.1815 1.16626 12.3596 1.21563 12.5151 1.30907H12.5141Z' fill='white'/%3E%3C/svg%3E%0A");
        }

        .icon-service {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 2H17.5C17.6552 2 17.8084 2.03614 17.9472 2.10557C18.0861 2.175 18.2069 2.2758 18.3 2.4L21 6V21C21 21.2652 20.8946 21.5196 20.7071 21.7071C20.5196 21.8946 20.2652 22 20 22H4C3.73478 22 3.48043 21.8946 3.29289 21.7071C3.10536 21.5196 3 21.2652 3 21V6L5.7 2.4C5.79315 2.2758 5.91393 2.175 6.05279 2.10557C6.19164 2.03614 6.34475 2 6.5 2ZM18.5 6L17 4H7L5.5 6H18.5ZM9 10H7V12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12V10H15V12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12V10Z' fill='white'/%3E%3C/svg%3E%0A");
        }

        .icon-cert {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 9V20.993C21.0009 21.1243 20.976 21.2545 20.9266 21.3762C20.8772 21.4979 20.8043 21.6087 20.7121 21.7022C20.6199 21.7957 20.5101 21.8701 20.3892 21.9212C20.2682 21.9723 20.1383 21.9991 20.007 22H3.993C3.72981 22 3.47739 21.8955 3.2912 21.7095C3.105 21.5235 3.00027 21.2712 3 21.008V2.992C3 2.455 3.447 2 3.998 2H14V8C14 8.26522 14.1054 8.51957 14.2929 8.70711C14.4804 8.89464 14.7348 9 15 9H21ZM21 7H16V2.003L21 7ZM8 7V9H11V7H8ZM8 11V13H16V11H8ZM8 15V17H16V15H8Z' fill='white'/%3E%3C/svg%3E%0A");
        }

        .icon-dev {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 12L18.343 17.657L16.929 16.243L21.172 12L16.929 7.757L18.343 6.343L24 12ZM2.828 12L7.071 16.243L5.657 17.657L0 12L5.657 6.343L7.07 7.757L2.828 12ZM9.788 21H7.66L14.212 3H16.34L9.788 21Z' fill='white'/%3E%3C/svg%3E%0A");
        }

        .icon-kon {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.95 11.9499C17.7971 11.1036 18.4128 10.0539 18.7381 8.90146C19.0634 7.74902 19.0875 6.53235 18.808 5.36795L21.303 4.29795C21.3791 4.26533 21.4621 4.25211 21.5445 4.25949C21.627 4.26686 21.7063 4.29459 21.7754 4.34019C21.8445 4.38579 21.9012 4.44783 21.9404 4.52074C21.9796 4.59366 22.0001 4.67516 22 4.75795V18.9999L15 21.9999L9 18.9999L2.697 21.7009C2.62091 21.7336 2.53792 21.7468 2.45547 21.7394C2.37301 21.732 2.29368 21.7043 2.22459 21.6587C2.1555 21.6131 2.09881 21.5511 2.05961 21.4781C2.02041 21.4052 1.99993 21.3237 2 21.2409V6.99995L5.129 5.65895C4.90884 6.78452 4.96891 7.94698 5.30391 9.04387C5.6389 10.1408 6.23855 11.1384 7.05 11.9489L12 16.8999L16.95 11.9499ZM15.536 10.5359L12 14.0699L8.464 10.5349C7.76487 9.83563 7.2888 8.94471 7.09598 7.97484C6.90316 7.00498 7.00225 5.99971 7.38073 5.08616C7.75921 4.1726 8.40007 3.39179 9.22229 2.84244C10.0445 2.29309 11.0111 1.99988 12 1.99988C12.9889 1.99988 13.9555 2.29309 14.7777 2.84244C15.5999 3.39179 16.2408 4.1726 16.6193 5.08616C16.9977 5.99971 17.0968 7.00498 16.904 7.97484C16.7112 8.94471 16.2351 9.83563 15.536 10.5349V10.5359Z' fill='white'/%3E%3C/svg%3E%0A");
        }
      }

      span {
        font-weight: 400;
        font-size: 14px;
        opacity: 0.6;
      }

      .icons-svg {
        width: 24px;
        height: 24px;
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        margin-bottom: 4px;
      }

      // GLOBAL PAY

      //inactive

      .icon-gate {
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.0831 15.2001L21.2851 15.9211C21.3593 15.9655 21.4206 16.0283 21.4633 16.1035C21.5059 16.1787 21.5283 16.2636 21.5283 16.3501C21.5283 16.4365 21.5059 16.5214 21.4633 16.5966C21.4206 16.6718 21.3593 16.7347 21.2851 16.7791L12.5151 22.0411C12.3596 22.1345 12.1815 22.1839 12.0001 22.1839C11.8187 22.1839 11.6406 22.1345 11.4851 22.0411L2.7151 16.7791C2.64095 16.7347 2.57956 16.6718 2.53695 16.5966C2.49433 16.5214 2.47192 16.4365 2.47192 16.3501C2.47192 16.2636 2.49433 16.1787 2.53695 16.1035C2.57956 16.0283 2.64095 15.9655 2.7151 15.9211L3.9171 15.2001L12.0001 20.0501L20.0831 15.2001V15.2001ZM20.0831 10.5001L21.2851 11.2211C21.3593 11.2655 21.4206 11.3283 21.4633 11.4035C21.5059 11.4787 21.5283 11.5636 21.5283 11.6501C21.5283 11.7365 21.5059 11.8214 21.4633 11.8966C21.4206 11.9718 21.3593 12.0347 21.2851 12.0791L12.0001 17.6501L2.7151 12.0791C2.64095 12.0347 2.57956 11.9718 2.53695 11.8966C2.49433 11.8214 2.47192 11.7365 2.47192 11.6501C2.47192 11.5636 2.49433 11.4787 2.53695 11.4035C2.57956 11.3283 2.64095 11.2655 2.7151 11.2211L3.9171 10.5001L12.0001 15.3501L20.0831 10.5001ZM12.5141 1.30907L21.2851 6.57107C21.3593 6.61546 21.4206 6.67832 21.4633 6.75351C21.5059 6.82869 21.5283 6.91364 21.5283 7.00007C21.5283 7.0865 21.5059 7.17145 21.4633 7.24663C21.4206 7.32182 21.3593 7.38468 21.2851 7.42907L12.0001 13.0001L2.7151 7.42907C2.64095 7.38468 2.57956 7.32182 2.53695 7.24663C2.49433 7.17145 2.47192 7.0865 2.47192 7.00007C2.47192 6.91364 2.49433 6.82869 2.53695 6.75351C2.57956 6.67832 2.64095 6.61546 2.7151 6.57107L11.4851 1.30907C11.6406 1.21563 11.8187 1.16626 12.0001 1.16626C12.1815 1.16626 12.3596 1.21563 12.5151 1.30907H12.5141ZM12.0001 3.33207L5.8871 7.00007L12.0001 10.6681L18.1131 7.00007L12.0001 3.33207Z' fill='white'/%3E%3C/svg%3E%0A");
      }

      .icon-service {
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.66667 2.6665H23.3333C23.5403 2.6665 23.7445 2.7147 23.9296 2.80727C24.1148 2.89984 24.2758 3.03424 24.4 3.19984L28 7.99984V27.9998C28 28.3535 27.8595 28.6926 27.6095 28.9426C27.3594 29.1927 27.0203 29.3332 26.6667 29.3332H5.33333C4.97971 29.3332 4.64057 29.1927 4.39052 28.9426C4.14048 28.6926 4 28.3535 4 27.9998V7.99984L7.6 3.19984C7.7242 3.03424 7.88524 2.89984 8.07038 2.80727C8.25552 2.7147 8.45967 2.6665 8.66667 2.6665ZM25.3333 10.6665H6.66667V26.6665H25.3333V10.6665ZM24.6667 7.99984L22.6667 5.33317H9.33333L7.33333 7.99984H24.6667ZM12 13.3332V15.9998C12 17.0607 12.4214 18.0781 13.1716 18.8283C13.9217 19.5784 14.9391 19.9998 16 19.9998C17.0609 19.9998 18.0783 19.5784 18.8284 18.8283C19.5786 18.0781 20 17.0607 20 15.9998V13.3332H22.6667V15.9998C22.6667 17.7679 21.9643 19.4636 20.714 20.7139C19.4638 21.9641 17.7681 22.6665 16 22.6665C14.2319 22.6665 12.5362 21.9641 11.286 20.7139C10.0357 19.4636 9.33333 17.7679 9.33333 15.9998V13.3332H12Z' fill='white' fill-opacity='0.7'/%3E%3C/svg%3E%0A");
      }

      .icon-cert {
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M28 10.6665V27.9905C28.0012 28.1656 27.968 28.3392 27.9021 28.5015C27.8362 28.6637 27.739 28.8114 27.6161 28.9361C27.4931 29.0607 27.3468 29.16 27.1855 29.2281C27.0242 29.2963 26.8511 29.3319 26.676 29.3332H5.324C4.97308 29.3332 4.63652 29.1939 4.38826 28.9459C4.14 28.6978 4.00035 28.3614 4 28.0105V3.98917C4 3.27317 4.59867 2.6665 5.336 2.6665H19.996L28 10.6665ZM25.3333 11.9998H18.6667V5.33317H6.66667V26.6665H25.3333V11.9998ZM10.6667 9.33317H14.6667V11.9998H10.6667V9.33317ZM10.6667 14.6665H21.3333V17.3332H10.6667V14.6665ZM10.6667 19.9998H21.3333V22.6665H10.6667V19.9998Z' fill='white' fill-opacity='0.7'/%3E%3C/svg%3E%0A");
      }

      .icon-dev {
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M32 16L24.4573 23.5427L22.572 21.6573L28.2293 16L22.572 10.3427L24.4573 8.45733L32 16ZM3.77067 16L9.428 21.6573L7.54267 23.5427L0 16L7.54267 8.45733L9.42667 10.3427L3.77067 16ZM13.0507 28H10.2133L18.9493 4H21.7867L13.0507 28Z' fill='white' fill-opacity='0.7'/%3E%3C/svg%3E%0A");
      }

      .icon-kon {
        background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.33329 8.19085V25.2895L12.0866 22.3962L20.0866 26.3962L26.6666 23.5735V6.47618L28.404 5.73218C28.5054 5.68869 28.6161 5.67107 28.726 5.6809C28.8359 5.69074 28.9417 5.72771 29.0338 5.78851C29.126 5.84931 29.2016 5.93203 29.2538 6.02924C29.3061 6.12646 29.3334 6.23514 29.3333 6.34551V25.3335L20 29.3335L12 25.3335L3.59596 28.9348C3.49451 28.9783 3.38385 28.996 3.27391 28.9861C3.16398 28.9763 3.0582 28.9393 2.96608 28.8785C2.87395 28.8177 2.79837 28.735 2.7461 28.6378C2.69384 28.5406 2.66653 28.4319 2.66663 28.3215V9.33351L5.33329 8.19085ZM21.6573 14.9908L16 20.6468L10.3426 14.9908C9.22394 13.872 8.46215 12.4465 8.15358 10.8947C7.84501 9.34289 8.00351 7.73442 8.60905 6.27269C9.21459 4.81096 10.24 3.56161 11.5555 2.68262C12.8711 1.80363 14.4178 1.33447 16 1.33447C17.5822 1.33447 19.1288 1.80363 20.4444 2.68262C21.7599 3.56161 22.7853 4.81096 23.3909 6.27269C23.9964 7.73442 24.1549 9.34289 23.8463 10.8947C23.5378 12.4465 22.776 13.872 21.6573 14.9908V14.9908ZM16 16.8762L19.7706 13.1042C20.5167 12.3584 21.0248 11.4081 21.2307 10.3734C21.4366 9.33884 21.3311 8.2664 20.9275 7.29177C20.5239 6.31714 19.8403 5.48409 18.9632 4.89798C18.0861 4.31188 17.0549 3.99904 16 3.99904C14.9451 3.99904 13.9138 4.31188 13.0368 4.89798C12.1597 5.48409 11.4761 6.31714 11.0724 7.29177C10.6688 8.2664 10.5633 9.33884 10.7692 10.3734C10.9751 11.4081 11.4833 12.3584 12.2293 13.1042L16 16.8762Z' fill='white' fill-opacity='0.7'/%3E%3C/svg%3E%0A");
      }
    }

    .nav-link {
      padding: 10px;
      color: #fff;
      opacity: 0.5;
      @media (max-width: 768px) {
        font-size: 12px;
      }

      i {
        display: block;
        font-size: 24px;
        line-height: 1.3;
      }

      span {
        display: inline-block;
      }
    }

    .nav-link:hover {
      opacity: 0.8;
      transition: 0.3s;
    }

    .nav-link {
      border-bottom: 2px solid transparent;
    }

    .nav-link.active {
      opacity: 1;
      border-color: rgba(255, 255, 255, 1);
    }
  }
`;
