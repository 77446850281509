import { useTranslation } from "react-i18next";
import { Content } from "../../style/content.style";

export const Capital = () => {
    const { t } = useTranslation();

    return (
        <Content>
            <h1>{t("investors.capital")}</h1>
            <h3>28.06.2024</h3>
            <p>
                В соответствии с Постанавлением Президента Республики Узбекистан
                №ПП-381 от 30 ноября 2023г. «О мерах по уселению защиты прав
                потребителей цифровой продукции (услуг) и борьбы с
                правонарушениями, соверашемыми посредством цифровых технологий.
            </p>
            <p>
                На основании решения №Р1015-2 от 28.06.2024г. о выпуске
                дополнительных акций в размере 8 251 840 410 сум, одобренное
                Национальным агентством перспективных проектов при Президенте
                Республики Узбекистан, Уставной фонд компании увеличился до 13
                795 679 205 сум.
            </p>
            <h3>29.04.2024</h3>
            <p>
                В соответствии с Постанавлением Президента Республики Узбекистан
                №ПП-381 от 30 ноября 2023г. «О мерах по уселению защиты прав
                потребителей цифровой продукции (услуг) и борьбы с
                правонарушениями, соверашемыми посредством цифровых технологий.
            </p>
            <p>
                Наша организация на основании решения №Р1015-1 от 29.04.2024г. о
                выпуске дополнительных акций, одобренное Национальным агентством
                перспективных проектов при Президенте Республики Узбекистан,
                увеличила Уставной фонд до 5 543 838 795.
            </p>
        </Content>
    );
};
