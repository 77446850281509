import styled from "styled-components";
export const AdminPanel = styled.main`


.hide-scroll::-webkit-scrollbar { width: 0; height: 0; background: transparent;}
.hide-scroll { -ms-overflow-style: none; }
.hide-scroll { overflow: -moz-scrollbars-none; }
.hide-scroll { height: 30px}


`
