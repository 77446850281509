export const awardsDocuments = [
  {
    id: 1,
    title: "Test 1",
    desc: "Благодарственное письмо",
    img: "/images/about/test-award-1.jpg",
  },
  {
    id: 2,
    title: "Test 2",
    desc: "Благодарственное письмо",
    img: "/images/about/test-award-2.jpg",
  },
  {
    id: 3,
    title: "Test 3",
    desc: "Благодарственное письмо",
    img: "/images/about/test-award-3.jpg",
  },
  {
    id: 4,
    title: "Test 4",
    desc: "Благодарственное письмо",
    img: "/images/about/test-award-4.jpg",
  },
  {
    id: 5,
    title: "Test 5",
    desc: "Благодарственное письмо",
    img: "/images/about/test-award-1.jpg",
  },
];
