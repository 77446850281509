import styled from "styled-components";

export const SectionContent = styled.section`
  padding-top: 60px;
  padding-bottom: 60px;
  h3{
    font-weight: bold;
    margin-bottom: 30px;
  }
  .font-weight{font-weight: bold;}
`
